import React from 'react';
import cx from 'classnames';
import MLink from '../components/MLink';
import Layout from './index';
import './tabs.scss';

const LayoutTabs = props => {
  const {
    children,
    className,
    title,
    activeTab,
    route,
    pathname,
  } = props;

  const genTabClasses = (label, active) => cx('layout-tabs-content-tabs-menu-item', {
    active: active === label,
  });

  return (
    <Layout pathname={pathname} title={title} className={cx('layout-tabs', className)}>
      <div className="layout-tabs-content">
        <div className="layout-tabs-content-tabs">
          <div className="layout-tabs-content-tabs-menu">
            <MLink
              to={`/${route}/newcomer`}
              className={genTabClasses('newcomer', activeTab)}
            >
              <div className="layout-tabs-content-tabs-menu-item-icon newcomer" />
              <div className="layout-tabs-content-tabs-menu-item-label">
                Newcomer
              </div>
            </MLink>
            <MLink
              to={`/${route}/experienced`}
              className={genTabClasses('experienced', activeTab)}
            >
              <div className="layout-tabs-content-tabs-menu-item-icon experienced" />
              <div className="layout-tabs-content-tabs-menu-item-label">
                Experienced
              </div>
            </MLink>
            <MLink
              to={`/${route}/enterprise`}
              className={genTabClasses('enterprise', activeTab)}
            >
              <div className="layout-tabs-content-tabs-menu-item-icon enterprise" />
              <div className="layout-tabs-content-tabs-menu-item-label">
                Enterprise
              </div>
            </MLink>
          </div>
          <div className="layout-tabs-content-tabs-content">
            {children}
          </div>
        </div>
      </div>
    </Layout>
  );
};

LayoutTabs.defaultProps = {
  className: '',
};

export default LayoutTabs;
