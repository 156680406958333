import React from 'react';
import Img from '@components/Img';
import './HomeStatement.scss';

/**
 * @param {String} props.title required.
 * @param {String} props.text text under title.
 * @param {String} props.mainImage image's url.
 * @param {String} props.title image's url (requires props.mainImage).
 */
const HomeStatement = props => {
  const {
    title,
    text,
    desktopImage,
    images,
  } = props;

  return (
    <div className="home-statement mx-auto px-3 mb-5 text-center">
      <h3 className="home-statement-title mb-4">{title}</h3>
      {text && (
        <p className="home-statement-text mt-3 mb-4">{text}</p>
      )}
      {desktopImage && (
        <div className="home-statement-desktop-image mx-auto ">
          <Img
            alt={title}
            className="desktop-image"
            src={desktopImage}
          />
        </div>
      )}
      <div className="home-statement-images mx-auto">
        {images.map((image, index) => (
          <Img
            key={`home-statement-image-${image}`}
            alt={title}
            className={`image ${index ? 'opt' : 'main'}`}
            src={image}
          />
        ))}
      </div>
    </div>
  );
};

HomeStatement.defaultProps = {
  images: [],
};

export default HomeStatement;
