import React from 'react';
import { graphql } from 'gatsby';
import Img from '@components/Img';
import LayoutTabs from '../../layout/tabs';
import Sponsors from '../../components/Sponsors';
// import MLink from '../../components/MLink';
import ContentCards from '../../components/ContentCards';
import HomeStatement from '../../components/HomeStatement';
import './dive.scss';

const NewcomerPage = props => {
  const {
    data: {
      dataYaml: { homepage: data },
    },
    location,
  } = props;

  return (
    <LayoutTabs pathname={location.pathname} route="dive" title="Dive" activeTab="newcomer">
      <div className="dive-page-content">
        <div className="dive-page-content-header">
          <h2 className="dive-page-content-header-title">
            MLReef gives you an easy way into Machine Learning
          </h2>

          <p className="dive-page-content-header-text">
            Machine Learning (ML) is made easy and accessible. MLReef gives you the tools you need to help shape the
            ML innovation process. A no-code approach allows you to directly participate in data preparation and
            model training, without any Data Science background!
          </p>
        </div>
        <div className="featured-list">
          <div className="featured-list-content">
            <h3>
              Simple no/low code development
            </h3>
            <p>
              Empower your team with your knowledge and support via a easy to use 
              development environment. All AI Modules are usable as drag and drop 
              elements - being part will empower you to increase the quality and efficiency
              of ML innovation!
            </p>
            <ul className="featured-list-content-list">
              <li className="">
                No previous knowledge required
              </li>
              <li className="">
                Plenty of hands-on tutorials and use cases
              </li>
              <li className="">
                Learn by discovering via drag and drop
              </li>
            </ul>
          </div>
          <div className="featured-list-image border-rounded">
            <Img
              className=""
              src="/images/dive/MLReef_repository.png"
              alt="mlreef | Explore, fork and start creating"
            />
          </div>
        </div>

        <div className="featured-list reverse">
          <div className="featured-list-content">
            <h3>
              Try Machine Learning by doing
            </h3>
            <p>
              Use existing AI Modules to learn how to process data and create experiments
              with a simple drag and drop. This way you can iterate and learn the processes
              of ML without the need to see or touch any code.
            </p>
            <ul className="featured-list-content-list">
              <li className="">
                A structured framework guiding you to your first results
              </li>
              <li className="">
                Forget about environment setup and non working scripts
              </li>
              <li className="">
                Construct your ML projects module by module
              </li>
            </ul>
          </div>
          <div className="featured-list-image border-rounded">
            <Img
              className=""
              src="/images/dive/MLReef_creating_pipelines.png"
              alt="mlreef | Drag and drop your way into Machine Learning"
            />
          </div>
        </div>

        <div className="featured-list">
          <div className="featured-list-content">
            <h3>
              Learn from our knowledgebase
            </h3>
            <p>
              In the reef you are never alone. Share your troubles, ideas and experiences
              with fellows on your journey. Get pinpointed feedback
              by inviting others to explore every step you made.
            </p>
            <ul className="featured-list-content-list">
              <li className="">
                Speak the same language as your data scientists
              </li>
              <li className="">
                Share the process and collaborate directly
              </li>
              <li className="">
                Reproduce any results made with your team
              </li>
            </ul>
          </div>
          <div className="featured-list-image border-rounded">
            <Img
              className=""
              src="/images/dive/MLReef_explore_projects.png"
              alt="mlreef | Learn from our growing community"
            />
          </div>
        </div>
      </div>

      <Sponsors sponsors={data.sponsors} />

      <section className="banner-wide">
        <div className="banner-wide-title">
          Get started with Machine Learning!
        </div>
        <p className="banner-wide-text">
          Hop into MLReef and start learning the basics with our hands-on tutorials.
          Create your first models within minutes!
        </p>
      </section>

      <section className="home-page-section examples">
        <HomeStatement
          title="your Machine Learning development in one platform"
          text="Instead of disconnected toolchains, use MLReef to run your pipelines, process your data, version and track your experiments and model, manage your teams and members, ... and much more.."
        />
      </section>

      <ContentCards className="pb-4" />

      <div className="banner-short dark card mt-4 mb-5">
        <div className="banner-short-container card-container banner pt-2 pb-4">
          <div className="m-auto">
            <p>
              Be part of the Machine revolution!
            </p>
            <a
              href="https://www.mlreef.com/book-a-demo"
              className="btn btn-outline-primary mx-auto mt-3"
              target="_blank"
              rel="noreferrer noopener"
            >
              Book your demo
            </a>
          </div>
        </div>
      </div>
    </LayoutTabs>
  );
};

export const query = graphql`
  query NewcomerPageData {
    dataYaml {
      homepage {
        sponsors {
          logo
          title
          link
        }
      }
    }
  }
 `;

export default NewcomerPage;
