import React from 'react';

const Sponsors = ({ sponsors }) => (
  <>
    {sponsors && (
      <section className="home-page-section sponsors">
        <p className="sponsors-title">Supported and powered by</p>
        <div className="sponsors-container">
          {sponsors.map(spon => (
            <div
              className="sponsor"
              key={`sponsor-${spon.logo}`}
              title={spon.title}
              style={{ backgroundImage: `url(${spon.logo})` }}
            >
              {spon.link && (
                <a
                  href={spon.link}
                  className="sponsor-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  label={`go to ${spon.title}`}
                  aria-label={`go to ${spon.title}`}
                />
              )}
            </div>
          ))}
        </div>
      </section>
    )}

    <section className="home-page-section sponsors">
      <p className="sponsors-title">View source code & contribute</p>
      <div className="sponsors-container">
        <div
          className="sponsor"
          title="source code at gitlab"
          style={{ backgroundImage: 'url(/icons/gitlab-icon.png)' }}
        >
          <a
            href="https://gitlab.com/mlreef/mlreef"
            className="sponsor-link"
            target="_blank"
            rel="noopener noreferrer"
            label="go to MLReef repository in gitlab"
            aria-label="go to MLReef repository in gitlab"
          />
        </div>
        <div
          className="sponsor"
          title="source code at github"
          style={{ backgroundImage: 'url(/icons/github-icon.png)' }}
        >
          <a
            href="https://github.com/MLReef/mlreef"
            className="sponsor-link"
            target="_blank"
            rel="noopener noreferrer"
            label="go to MLReef repository in github"
            aria-label="go to MLReef repository in github"
          />
        </div>
      </div>
    </section>
  </>
);

export default Sponsors;
